import React, { useRef } from "react"
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
} from "react-leaflet"
import { graphql } from "gatsby"
import MarkerClusterGroup from "react-leaflet-markercluster"
import "react-leaflet-markercluster/dist/styles.min.css"
import L, { marker } from "leaflet"
import { Icon } from "leaflet"

import orangePin from "../images/orange-pin-1.png"
import orangePinCluster from "../images/orange-pin.png"
import Layout from "../components/layout"
import { PopupWrapper, Wrapper } from "../components/mapStyles"

export const query = graphql`
  query {
    allMarkers {
      edges {
        node {
          id
          address
          city
          country
          countryCode
          geolocationPlain
          name
          publicURL
          state
          zip
        }
      }
    }
  }
`

const Map = ({ data }) => {
  // const orangeMarker = new Icon({
  //   iconUrl: orangePin,
  //   iconSize: [27, 35],
  //   iconAnchor: [13, 35],
  //   popupAnchor: [0, -30],
  // })

  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `
        <div>
        <img src=${orangePinCluster} />
        <span>${cluster.getChildCount()}</span>
        </div>
      `,
      className: "marker-cluster-custom",
      iconSize: L.point(40, 40, true),
    })
  }

  const reformatMarkers = function (markersQuery) {
    return markersQuery.map(function (markersQuery) {
      const marker = {}
      marker["name"] = markersQuery.node.name
      marker[
        "publicURL"
      ] = `https://venue.kulturecity.org/venues/${markersQuery.node.publicURL}`
      marker["country"] = markersQuery.node.country
      marker["countryCode"] = markersQuery.node.countryCode
      marker["city"] = markersQuery.node.city
      marker["state"] = markersQuery.node.state
      marker["zip"] = markersQuery.node.zip
      marker["geolocationPlain"] = markersQuery.node.geolocationPlain
      return marker
    })
  }

  const markers = reformatMarkers(data.allMarkers.edges)

  return (
    <Layout>
      <Wrapper>
        {typeof window !== "undefined" && (
          <MapContainer
            center={[39.381266, -97.922211]}
            zoom={5}
            style={{ height: "100vh" }}
            tap={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <MarkerClusterGroup
              showCoverageOnHover={false}
              iconCreateFunction={createClusterCustomIcon}
              maxClusterRadius="50"
            >
              {markers
                .filter(marker => marker.geolocationPlain)
                .map(marker => (
                  <Marker
                    title={marker.name}
                    position={[
                      marker.geolocationPlain[0],
                      marker.geolocationPlain[1],
                    ]}
                    icon={
                      new Icon({
                        iconUrl: orangePin,
                        iconSize: [27, 35],
                        iconAnchor: [13, 35],
                        popupAnchor: [0, -30],
                      })
                    }
                  >
                    <Popup>
                      <PopupWrapper>
                        <h2>{marker.name}</h2>
                        <a href={marker.publicURL} target="_blank">
                          More details
                        </a>
                      </PopupWrapper>
                    </Popup>
                  </Marker>
                ))}
            </MarkerClusterGroup>
          </MapContainer>
        )}
      </Wrapper>
    </Layout>
  )
}
export default Map
